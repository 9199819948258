<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-03-30 13:09:07
 * @ Description: Fixer's job view and edit page in the user's job dashboard section of the app.
 -->

<template>
    <v-row
        justify="center"
    >
        <!-- Desktop -->
        <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
            cols="12"
        >
            <v-form
                v-model="validJobForm"
                ref="jobForm"
                id="print"
            >
                <v-row>
                    <!-- Back Button & Job Name -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <v-btn
                            icon
                            @click="back($store.state.storeRouter.currentRoute)"
                            class="mb-1"
                        >
                            <v-icon
                                size="24"
                                color="#2E567A"
                                style="opacity: 0.65;"
                            >
                                {{ mdiArrowLeftThinCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-icon
                            color="#2E567A"
                            size="30"
                            class="mr-1"
                        >
                            {{ mdiFlashOutline }}
                        </v-icon>
                        {{ selectedJob.name }}
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                        v-if="!isPrint"
                    >
                        <v-divider
                            style="width: 50%;"
                        />
                    </v-col>
                    <!-- Back Button & Job Name -->

                    <!-- Required Details -->
                    <v-col
                        :cols="isPrint ? 12 : 6"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="pb-0 os-15-sb"
                            >
                                Job Name<span v-if="editMode" style="color: red;">*</span>
                                <br />
                                <v-text-field
                                    v-if="editMode"
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    placeholder="Job Name (Add a descriptive name)"
                                    :rules="shortNameRules"
                                    counter="30"
                                    v-model="selectedJob.name"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedJob.name">{{ selectedJob.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No name added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-15-sb py-0"
                            >
                                Detailed Description<span v-if="editMode" style="color: red;">*</span>
                                <br />
                                <general-tip-tap-text-editor-component
                                    v-if="editMode"
                                    class="pb-6"
                                    @textChanged="descriptionChanged"
                                    :placeholder="'Specific details about the job...'"
                                    :text="selectedJob.description"
                                />
                                <div
                                    v-if="editMode && !validDescription"
                                    style="color: red;"
                                    class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                >
                                    Required field
                                </div>
                                <div
                                    v-else-if="!editMode && selectedJob.description"
                                    class="os-13-r pt-3"
                                    style="color: #2E567A;"
                                    v-html="selectedJob.description"
                                />
                                <div
                                    v-else-if="!editMode && !selectedJob.description"
                                    class="os-12-r py-3"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                >
                                    No description added
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Industry<span v-if="editMode" style="color: red;">*</span>
                                <br />
                                <v-select
                                    v-if="editMode"
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :items="industries"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    v-model="selectedJob.industry"
                                    :rules="selectRules"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedJob.industry.id">{{ selectedJob.industry.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No industry added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Job Title<span v-if="editMode" style="color: red;">*</span>
                                <br />
                                <v-select
                                    v-if="editMode"
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :items="jobTitles"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    v-model="selectedJob.job_title"
                                    :rules="selectRules"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedJob.job_title.id">{{ selectedJob.job_title.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No job title added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Job Type<span v-if="editMode" style="color: red;">*</span>
                                <br />
                                <v-select
                                    v-if="editMode"
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :items="jobTypes"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    v-model="selectedJob.type"
                                    :rules="selectRules"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedJob.type.id">{{ selectedJob.type.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No type added
                                    </span>
                                </div>
                            </v-col>
                            <!-- <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Job Mode
                                <br />
                                <v-select
                                    v-if="editMode"
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    Work Remotely
                                </div>
                            </v-col> -->
                        </v-row>
                    </v-col>

                    <!-- Banner & Edit Button -->
                    <v-col
                        class="os-13-r text-center px-0"
                        cols="3"
                        v-if="!isPrint"
                    >
                        <input
                            type="file"
                            style="display: none"
                            ref="banner"
                            accept="image/*"
                            @change="bannerSelected"
                        >
                        <div
                            style="min-height: 100px; display: flex; justify-content: center;"
                        >
                            <v-img
                                :src="banner ? banner : editMode ? '/dashboard/general/DashboardGeneralUploadImage.png' : '/general/FixerCompanyIcon.png'"
                                :max-height="banner ? '100px' : editMode ? '100px' : '50px'"
                                :max-width="banner ? '100px' : editMode ? '100px' : '50px'"
                                contain
                                class="mb-2 mx-auto"
                            />
                        </div>
                        <span v-if="editMode" @click="selectBanner" style="color: rgb(143, 155, 179); cursor: pointer;">Update Image</span>
                        <div
                            class="py-6"
                            v-if="!editMode && selectedJob.user_id === $store.state.user.userData.id && selectedJob.status.id < 3"
                        >
                            <!-- <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                                color="#2E567A"
                                @click="printJob"
                            >
                                Print Job
                            </v-btn> -->
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                                color="#FFA858"
                                @click="editMode = true"
                            >
                                Edit Job
                            </v-btn>
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#E2574C"
                                @click="confirmCancelJob"
                            >
                                Cancel Job
                            </v-btn>
                        </div>
                        <div
                            class="py-6"
                            v-else-if="!editMode && $store.state.user.userContext === 'individual' && selectedJob.status.id === 2"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="$router.push(`/job/${$route.params.id}/application`)"
                            >
                                Apply
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- Banner & Edit Button -->

                    <v-col
                        :cols="isPrint ? 12 : 6"
                        class="py-0 os-15-sb"
                    >
                        Job Mode<span v-if="editMode" style="color: red;">*</span>
                        <br />
                        <v-select
                            v-if="editMode"
                            dense
                            outlined
                            color="#2E567A"
                            class="pt-2 os-13-r"
                            background-color="white"
                            :items="jobModes"
                            item-text="name"
                            item-value="id"
                            return-object
                            v-model="selectedJob.mode"
                            :rules="selectRules"
                        />
                        <div
                            v-else
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedJob.mode.id">{{ selectedJob.mode.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No mode added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        v-if="selectedJob.mode.id === 2"
                    >
                        <v-row
                            style="max-width: 50%;"
                        >
                            <v-col
                                cols="12"
                                class="py-0 pr-0 os-15-sb"
                            >
                                Address<span v-if="editMode" style="color: red;">*</span>
                                <br />
                                <general-google-autocomplete-address-component
                                    v-show="editMode"
                                    @googlePlaceSelected="googlePlaceSelected"
                                    @addressChanged="addressChanged"
                                    :placeholder="'Type the address to start a Google Maps lookup'"
                                    :address="address"
                                    :editMode="editMode"
                                />
                                <div
                                    v-if="!editMode"
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    {{ address.address_line_1 }}
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 pr-0 os-15-sb"
                            >
                                Country
                                <br />
                                <v-text-field
                                    v-if="editMode"
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    readonly
                                    v-model="address.country.name"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="address.country.name">{{ address.country.name }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No country added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0 pr-0 os-15-sb"
                            >
                                City
                                <br />
                                <v-text-field
                                    v-if="editMode"
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    readonly
                                    v-model="address.city"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="address.city">{{ address.city }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No city added
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Map -->
                    <v-col
                        :cols="isPrint ? 12 : 6"
                        class="pt-0"
                        v-if="selectedJob.mode.id === 2"
                    >
                        <general-map-component
                            :mapMarkers="mapMarkers"
                            :avatar="selectedJob.company_avatar"
                        />
                    </v-col>
                    <!-- Map -->
                    <!-- Required Details -->

                    <!-- Create/Save/Cancel Buttons -->
                    <v-col
                        cols="6"
                        align-self="end"
                        :class="selectedJob.mode.id === 2 ? '' : 'pb-7'"
                        v-if="editMode && !isPrint"
                    >
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            :disabled="cannotSaveJob"
                            @click="recruit(false)"
                            v-if="selectedJob.status.id === 1"
                        >
                            Create Job
                            <v-icon
                                class="ml-1"
                            >
                                {{ mdiPlusCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            :disabled="cannotSaveJob"
                            @click="upsertJob()"
                            v-else
                        >
                            Save
                        </v-btn>
                        <v-btn
                            text
                            class="no-uppercase rounded-lg os-13-sb"
                            color="#8F9BB3"
                            @click="back($store.state.storeRouter.currentRoute)"
                        >
                            Cancel
                        </v-btn>
                    </v-col>
                    <!-- Create/Save/Cancel Buttons -->

                    <!-- More Details -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <span v-if="selectedJob.user_id === $store.state.user.userData.id && selectedJob.status.id < 3">Add </span>More Details
                    </v-col>
                    <v-col
                        cols="6"
                        class="py-0"
                        v-if="!isPrint"
                    >
                        <v-divider />
                    </v-col>
                    <!-- More Details -->

                    <!-- Milestones -->
                    <v-col
                        cols="12"
                        class="os-15-sb pb-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandPaymentMilestones = !expandPaymentMilestones"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandPaymentMilestones"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-else
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Payment Milestones
                        <v-row
                            v-if="expandPaymentMilestones"
                            class="pt-3 pb-6"
                        >
                            <v-col
                                cols="6"
                            >
                                <general-work-payment-milestones-component
                                    :canEditPaymentMilestones="canEditPaymentMilestones"
                                    :canPayPaymentMilestones="canPayPaymentMilestones"
                                    :canRefreshPaymentMilestones="canRefreshPaymentMilestones"
                                    :editMode="editMode"
                                    :paymentMilestones="paymentMilestones"
                                    @savePaymentMilestones="confirmSavePaymentMilestones"
                                    @removePaymentMilestone="removePaymentMilestone"
                                    @refreshPaymentMilestones="getJobPaymentMilestones"
                                    @getPaymentMilestonesInMemory="getPaymentMilestonesInMemory"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Milestones -->

                    <!-- Dates -->
                    <v-col
                        cols="12"
                        class="os-15-sb py-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandDates = !expandDates"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandDates"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                v-else
                                color="#FFA838"
                                size="24"
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Job Date(s)
                        <v-row
                            v-if="expandDates"
                            style="max-width: 50%;"
                            class="pt-3"
                        >
                            <v-col
                                cols="6"
                                class="os-15-sb"
                            >
                                Start Date
                                <br />
                                <v-menu
                                    v-model="startDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-if="editMode"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="selectedJob.start_date"
                                            :rules="startDateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                            :append-icon="mdiClose"
                                            @click:append="selectedJob.start_date = null"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="selectedJob.start_date"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="startDateMenu = false"
                                    />
                                </v-menu>
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedJob.start_date">{{ selectedJob.start_date }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No start date added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="6"
                                class="os-15-sb pr-0"
                            >
                                End Date
                                <br />
                                <v-menu
                                    v-model="endDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-if="editMode"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="selectedJob.end_date"
                                            :rules="endDateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                            :append-icon="mdiClose"
                                            @click:append="selectedJob.end_date = null"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="selectedJob.end_date"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="endDateMenu = false"
                                    />
                                </v-menu>
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedJob.end_date">{{ selectedJob.end_date }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No end date added
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Dates -->

                    <!-- Documents -->
                    <v-col
                        cols="12"
                        class="os-15-sb pt-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandDocuments = !expandDocuments"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandDocuments"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                v-else
                                color="#FFA838"
                                size="24"
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Document(s)
                        <v-row
                            v-if="expandDocuments"
                            class="pb-6"
                            style="max-width: 50%;"
                            :class="editMode ? 'pt-3' : 'pt-6'"
                        >
                            <v-col
                                cols="12"
                            >
                                <general-document-component
                                    :documents="jobDocuments"
                                    :canEditDocuments="editMode"
                                    :fileType="'document'"
                                    @deleteDocument="confirmDeleteDocument"
                                    @downloadDocument="downloadDocument"
                                    @createDocument="createDocument"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Documents -->
                </v-row>
            </v-form>
        </v-col>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-col
            v-if="$vuetify.breakpoint.smAndDown"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
            cols="12"
        >
            <v-form
                v-model="validJobForm"
                ref="jobForm"
            >
                <v-row>
                    <!-- Back Button & Job Name -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <v-btn
                            icon
                            @click="back($store.state.storeRouter.currentRoute)"
                            class="mb-1"
                        >
                            <v-icon
                                size="24"
                                color="#2E567A"
                                style="opacity: 0.65;"
                            >
                                {{ mdiArrowLeftThinCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-icon
                            color="#2E567A"
                            size="30"
                            class="mr-1"
                        >
                            {{ mdiFlashOutline }}
                        </v-icon>
                        {{ selectedJob.name }}
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider />
                    </v-col>
                    <!-- Back Button & Job Name -->

                    <!-- Banner & Edit Button -->
                    <v-col
                        class="os-13-r text-center px-0"
                        cols="12"
                    >
                        <input
                            type="file"
                            style="display: none"
                            ref="banner"
                            accept="image/*"
                            @change="bannerSelected"
                        >
                        <div
                            style="min-height: 100px; display: flex; justify-content: center;"
                        >
                            <v-img
                                :src="banner ? banner : '/dashboard/general/DashboardGeneralUploadImage.png'"
                                max-height="100px"
                                max-width="100px"
                                contain
                                class="mb-2 mx-auto"
                            />
                        </div>
                        <span v-if="editMode" @click="selectBanner" style="color: rgb(143, 155, 179); cursor: pointer;">Update Image</span>
                        <div
                            class="pt-6"
                            v-if="!editMode && selectedJob.user_id === $store.state.user.userData.id && selectedJob.status.id < 3"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                                color="#FFA858"
                                @click="editMode = true"
                            >
                                Edit Job
                            </v-btn>
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#E2574C"
                                @click="confirmCancelJob"
                            >
                                Cancel Job
                            </v-btn>
                        </div>
                        <div
                            class="pt-6"
                            v-else-if="!editMode && $store.state.user.userContext === 'individual' && selectedJob.status.id === 2"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="$router.push(`/job/${$route.params.id}/application`)"
                            >
                                Apply
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- Banner & Edit Button -->

                    <!-- Required Details -->
                    <v-col
                        cols="12"
                        class="pb-0 os-15-sb"
                    >
                        Job Name<span v-if="editMode" style="color: red;">*</span>
                        <br />
                        <v-text-field
                            v-if="editMode"
                            dense
                            outlined
                            color="#2E567A"
                            class="pt-2 os-13-r"
                            background-color="white"
                            placeholder="Job Name (Add a descriptive name)"
                            :rules="shortNameRules"
                            v-model="selectedJob.name"
                            counter="30"
                        />
                        <div
                            v-else
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedJob.name">{{ selectedJob.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No name added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        class="os-15-sb py-0"
                    >
                        Detailed Description<span v-if="editMode" style="color: red;">*</span>
                        <br />
                        <general-tip-tap-text-editor-component
                            v-if="editMode"
                            class="pb-6"
                            @textChanged="descriptionChanged"
                            :placeholder="'Specific details about the job...'"
                            :text="selectedJob.description"
                        />
                        <div
                            v-if="editMode && !validDescription"
                            style="color: red;"
                            class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                        >
                            Required field
                        </div>
                        <div
                            v-else-if="!editMode && selectedJob.description"
                            class="os-13-r pt-3"
                            style="color: #2E567A;"
                            v-html="selectedJob.description"
                        />
                        <div
                            v-else-if="!editMode && !selectedJob.description"
                            class="os-12-r py-3"
                            style="color: rgba(0, 0, 0, 0.38);"
                        >
                            No description added
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Industry<span v-if="editMode" style="color: red;">*</span>
                        <br />
                        <v-select
                            v-if="editMode"
                            dense
                            outlined
                            color="#2E567A"
                            class="pt-2 os-13-r"
                            background-color="white"
                            :items="industries"
                            item-text="name"
                            item-value="id"
                            return-object
                            v-model="selectedJob.industry"
                            :rules="selectRules"
                        />
                        <div
                            v-else
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedJob.industry.id">{{ selectedJob.industry.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No industry added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Job Title<span v-if="editMode" style="color: red;">*</span>
                        <br />
                        <v-select
                            v-if="editMode"
                            dense
                            outlined
                            color="#2E567A"
                            class="pt-2 os-13-r"
                            background-color="white"
                            :items="jobTitles"
                            item-text="name"
                            item-value="id"
                            return-object
                            v-model="selectedJob.job_title"
                            :rules="selectRules"
                        />
                        <div
                            v-else
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedJob.job_title.id">{{ selectedJob.job_title.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No job title added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Job Type<span v-if="editMode" style="color: red;">*</span>
                        <br />
                        <v-select
                            v-if="editMode"
                            dense
                            outlined
                            color="#2E567A"
                            class="pt-2 os-13-r"
                            background-color="white"
                            :items="jobTypes"
                            item-text="name"
                            item-value="id"
                            return-object
                            v-model="selectedJob.type"
                            :rules="selectRules"
                        />
                        <div
                            v-else
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedJob.type.id">{{ selectedJob.type.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No type added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Job Mode<span v-if="editMode" style="color: red;">*</span>
                        <br />
                        <v-select
                            v-if="editMode"
                            dense
                            outlined
                            color="#2E567A"
                            class="pt-2 os-13-r"
                            background-color="white"
                            :items="jobModes"
                            item-text="name"
                            item-value="id"
                            return-object
                            v-model="selectedJob.mode"
                            :rules="selectRules"
                        />
                        <div
                            v-else
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="selectedJob.mode.id">{{ selectedJob.mode.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No mode added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        v-if="selectedJob.mode.id === 2"
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Address<span v-if="editMode" style="color: red;">*</span>
                        <br />
                        <general-google-autocomplete-address-component
                            @googlePlaceSelected="googlePlaceSelected"
                            @addressChanged="addressChanged"
                            :placeholder="'Type the address to start a Google Maps lookup'"
                            :address="address"
                            v-show="editMode"
                        />
                        <div
                            v-if="!editMode"
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            {{ address.address_line_1 }}
                        </div>
                    </v-col>
                    <v-col
                        v-if="selectedJob.mode.id === 2"
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        Country
                        <br />
                        <v-text-field
                            v-if="editMode"
                            dense
                            outlined
                            color="#2E567A"
                            class="pt-2 os-13-r"
                            background-color="white"
                            readonly
                            v-model="address.country.name"
                        />
                        <div
                            v-else
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="address.country.id">{{ address.country.name }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No country added
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        v-if="selectedJob.mode.id === 2"
                        cols="12"
                        sm="6"
                        class="py-0 os-15-sb"
                    >
                        City
                        <br />
                        <v-text-field
                            v-if="editMode"
                            dense
                            outlined
                            color="#2E567A"
                            class="pt-2 os-13-r"
                            background-color="white"
                            readonly
                            v-model="address.city"
                        />
                        <div
                            v-else
                            class="os-13-r py-3"
                            style="color: #2E567A;"
                        >
                            <span v-if="address.city">{{ address.city }}</span>
                            <span
                                v-else
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                            >
                                No name added
                            </span>
                        </div>
                    </v-col>

                    <!-- Map -->
                    <v-col
                        cols="12"
                        class="pt-0"
                        v-if="selectedJob.mode.id === 2"
                    >
                        <general-map-component
                            :mapMarkers="mapMarkers"
                            :avatar="selectedJob.company_avatar"
                        />
                    </v-col>
                    <!-- Map -->
                    <!-- Required Details -->

                    <!-- Create/Save/Cancel Buttons -->
                    <v-col
                        cols="12"
                        sm="12"
                        class="text-right"
                        :class="$vuetify.breakpoint.sm ? 'pb-7' : ''"
                        v-if="editMode"
                    >
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            :disabled="cannotSaveJob"
                            @click="recruit(false)"
                            v-if="selectedJob.status.id === 1"
                        >
                            Create Job
                            <v-icon
                                class="ml-1"
                            >
                                {{ mdiPlusCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            :disabled="cannotSaveJob"
                            @click="upsertJob()"
                            v-else
                        >
                            Save
                        </v-btn>
                        <v-btn
                            text
                            class="no-uppercase rounded-lg os-13-sb"
                            color="#8F9BB3"
                            @click="back($store.state.storeRouter.currentRoute)"
                        >
                            Cancel
                        </v-btn>
                    </v-col>
                    <!-- Create/Save/Cancel Buttons -->

                    <!-- More Details -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                    >
                        <span v-if="selectedJob.user_id === $store.state.user.userData.id && selectedJob.status.id < 3">Add </span>More Details
                    </v-col>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider />
                    </v-col>
                    <!-- More Details -->

                    <!-- Milestones -->
                    <v-col
                        cols="12"
                        class="os-15-sb pb-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandPaymentMilestones = !expandPaymentMilestones"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandPaymentMilestones"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-else
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Payment Milestones
                        <v-row
                            v-if="expandPaymentMilestones"
                            class="py-3 px-2"
                        >
                            <v-col
                                cols="12"
                            >
                                <general-work-payment-milestones-component
                                    :canEditPaymentMilestones="canEditPaymentMilestones"
                                    :canPayPaymentMilestones="canPayPaymentMilestones"
                                    :canRefreshPaymentMilestones="canRefreshPaymentMilestones"
                                    :editMode="editMode"
                                    :paymentMilestones="paymentMilestones"
                                    @savePaymentMilestones="confirmSavePaymentMilestones"
                                    @removePaymentMilestone="removePaymentMilestone"
                                    @refreshPaymentMilestones="getJobPaymentMilestones"
                                    @getPaymentMilestonesInMemory="getPaymentMilestonesInMemory"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Milestones -->

                    <!-- Dates -->
                    <v-col
                        cols="12"
                        class="os-15-sb py-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandDates = !expandDates"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandDates"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                v-else
                                color="#FFA838"
                                size="24"
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Job Date(s)
                        <v-row
                            v-if="expandDates"
                            class="pt-3"
                        >
                            <v-col
                                cols="12"
                                sm="6"
                                class="os-15-sb"
                                :class="$vuetify.breakpoint.xs ? 'pb-0' : ''"
                            >
                                Start Date
                                <br />
                                <v-menu
                                    v-model="startDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-if="editMode"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="selectedJob.start_date"
                                            :rules="startDateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                            :append-icon="mdiClose"
                                            @click:append="selectedJob.start_date = null"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="selectedJob.start_date"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="startDateMenu = false"
                                    />
                                </v-menu>
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedJob.start_date">{{ selectedJob.start_date }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No start date added
                                    </span>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="os-15-sb"
                                :class="$vuetify.breakpoint.xs ? 'pt-0' : ''"
                            >
                                End Date
                                <br />
                                <v-menu
                                    v-model="endDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-if="editMode"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="selectedJob.end_date"
                                            :rules="endDateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                            :append-icon="mdiClose"
                                            @click:append="selectedJob.end_date = null"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="selectedJob.end_date"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="endDateMenu = false"
                                    />
                                </v-menu>
                                <div
                                    v-else
                                    class="os-13-r py-3"
                                    style="color: #2E567A;"
                                >
                                    <span v-if="selectedJob.end_date">{{ selectedJob.end_date }}</span>
                                    <span
                                        v-else
                                        class="os-12-r"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No end date added
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Dates -->

                    <!-- Documents -->
                    <v-col
                        cols="12"
                        class="os-15-sb pt-0"
                    >
                        <v-btn
                            icon
                            style="mr-1 mb-1"
                            @click="expandDocuments = !expandDocuments"
                        >
                            <v-icon
                                color="#FFA838"
                                size="24"
                                v-if="expandDocuments"
                            >
                                {{ mdiMinusCircle }}
                            </v-icon>
                            <v-icon
                                v-else
                                color="#FFA838"
                                size="24"
                            >
                                {{ mdiPlusCircle }}
                            </v-icon>
                        </v-btn>
                        Document(s)
                        <v-row
                            v-if="expandDocuments"
                            class="pb-6"
                            :class="editMode ? 'pt-3' : 'pt-6'"
                        >
                            <v-col
                                cols="12"
                            >
                                <general-document-component
                                    :documents="jobDocuments"
                                    :canEditDocuments="editMode"
                                    :fileType="'document'"
                                    @deleteDocument="confirmDeleteDocument"
                                    @downloadDocument="downloadDocument"
                                    @createDocument="createDocument"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Documents -->
                </v-row>
            </v-form>
        </v-col>
        <!-- Mobile -->

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Document'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->

        <!-- Confirm Cancel Edit Overlay -->
        <general-confirm-cancel-edit-overlay-component
            :showCancelEditConfirmationOverlayBoolean="showCancelEditConfirmationOverlayBoolean"
            @showCancelEditConfirmationOverlay="showCancelEditConfirmationOverlay"
        />
        <!-- Confirm Cancel Edit Overlay -->

        <!-- Confirm Cancel Job Overlay -->
        <work-general-status-confirm-cancel-overlay-component
            :showCancelOverlayBoolean="showCancelOverlayBoolean"
            :jobToCancel="selectedJob"
            @toggleCancelOverlayComponent="toggleCancelOverlayComponent"
        />
        <!-- Confirm Cancel Job Overlay -->

        <!-- Confirm Save Milestones Overlay -->
        <work-general-payment-milestones-confirm-save-overlay-component
            :showSaveMilestonesConfirmationOverlayBoolean="showSaveMilestonesConfirmationOverlayBoolean"
            @showSaveMilestonesConfirmationOverlay="showSaveMilestonesConfirmationOverlay"
        />
        <!-- Confirm Save Milestones Overlay -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->
    </v-row>
</template>
<script>
    import { mdiFlashOutline, mdiPlusCircleOutline, mdiPlusCircle, mdiMinusCircle, mdiArrowLeftThinCircleOutline, mdiClose } from '@mdi/js'
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import GeneralWorkPaymentMilestonesComponent from '@/components/general/work/GeneralWorkPaymentMilestonesComponent.vue'
    import GeneralDocumentComponent from '@/components/general/document/GeneralDocumentComponent.vue'
    import GeneralMapComponent from '@/components/general/map/GeneralMapComponent.vue'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'
    import { GeneralInvoiceController, LookupJobController, LookupIndustryController, LookupJobTitleController, LookupLocationController, WorkJobDetailController, WorkJobDocumentController, WorkJobLocationController, WorkJobMilestoneController, WorkJobStatusController } from '@/logic/controller/index.controller.js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import GeneralConfirmCancelEditOverlayComponent from '@/overlays/general/GeneralConfirmCancelEditOverlayComponent.vue'
    import WorkGeneralStatusConfirmCancelOverlayComponent from '@/overlays/work/general/status/WorkGeneralStatusConfirmCancelOverlayComponent.vue'
    import WorkGeneralPaymentMilestonesConfirmSaveOverlayComponent from '@/overlays/work/general/WorkGeneralPaymentMilestonesConfirmSaveOverlayComponent.vue'
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'

    export default {
        name: 'WorkJobActionGeneralViewPage',

        components: {
            GeneralTipTapTextEditorComponent,
            GeneralWorkPaymentMilestonesComponent,
            GeneralDocumentComponent,
            GeneralMapComponent,
            GeneralGoogleAutocompleteAddressComponent,
            GeneralConfirmDeleteOverlayComponent,
            GeneralConfirmCancelEditOverlayComponent,
            WorkGeneralStatusConfirmCancelOverlayComponent,
            WorkGeneralPaymentMilestonesConfirmSaveOverlayComponent,
            PaymentOverlayComponent
        },

        watch: {
            '$route.params.id': {
                async handler (value) {
                    await this.getJobDetail()
                    await this.getJobDocuments()
                    await this.getJobLocation()
                    await this.getJobPaymentMilestones()
                }
            }
        },

        computed: {
            shortNameRules () {
                if (this.selectedJob.name) {
                    return [
                        v => !!v || 'Required field',
                        v => v.replace(/\s/g, '') !== '' || 'Required field',
                        v => v.length <= 30 || 'Max 30 characters'
                    ]
                } else return [ 'Required field' ]
            },

            selectRules () {
                return [
                    v => (!!v && !!v.id) || 'Required Field'
                ]
            },

            startDateRules () {
                let today = new Date()
                today = today.toISOString().split('T')[0]

                if (this.selectedJob.start_date === null) return []
                else if (this.selectedJob.start_date.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                else if (new Date(this.selectedJob.start_date.replace(/-/g, '/')).getTime() < new Date(today.replace(/-/g, '/')).getTime()) return [ 'Start cannot be before today' ]
                else if (this.selectedJob.end_date !== null && new Date(this.selectedJob.start_date.replace(/-/g, '/')).getTime() > new Date(this.selectedJob.end_date.replace(/-/g, '/')).getTime()) return [ 'Start must be before end' ]
                else return []
            },

            endDateRules () {
                if (this.selectedJob.end_date === null) return []
                else if (this.selectedJob.end_date.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                else if (this.selectedJob.start_date !== null && new Date(this.selectedJob.end_date.replace(/-/g, '/')).getTime() < new Date(this.selectedJob.start_date.replace(/-/g, '/')).getTime()) return [ 'End must be after start' ]
                else return []
            },

            cannotSaveJob () {
                if (!this.selectedJob.description) return true
                else if (this.selectedJob.mode.id === 1) return !this.validJobForm
                else return !this.validJobForm || this.address.google_places_id === null || this.googlePlaceAltered
            },

            canEditPaymentMilestones () {
                if (this.editMode && this.$store.state.user.userData.id === this.selectedJob.user_id) return true
                else return false
            },

            canPayPaymentMilestones () {
                if (!this.editMode && this.$store.state.user.userData.id === this.selectedJob.user_id && this.selectedJob.status.id === 3) return true
                else return false
            },

            canRefreshPaymentMilestones () {
                if (this.selectedJob.applicant_id === this.$store.state.user.userData.id && this.selectedJob.stage.id === 3) return true
                else return false
            }
        },

        data () {
            return {
                mdiFlashOutline,
                mdiPlusCircleOutline,
                mdiPlusCircle,
                mdiMinusCircle,
                mdiArrowLeftThinCircleOutline,
                mdiClose,
                expandDetailedDescription: false,
                expandDates: false,
                expandDocuments: false,
                expandPaymentMilestones: false,
                editMode: false,
                selectedJob: {
                    name: '',
                    description: null,
                    start_date: null,
                    end_date: null,
                    image_url: null,
                    status: {
                        id: null
                    },
                    industry: {
                        id: null
                    },
                    job_title: {
                        id: null
                    },
                    type: {
                        id: 1
                    },
                    mode: {
                        id: 1
                    }
                },
                jobDocuments: [],
                lookupJobController: null,
                lookupIndustryController: null,
                lookupJobTitleController: null,
                lookupLocationController: null,
                workJobDetailController: null,
                workJobDocumentController: null,
                workJobLocationController: null,
                workJobMilestoneController: null,
                workJobStatusController: null,
                generalInvoiceController: null,
                jobTypes: [],
                jobModes: [],
                industries: [],
                jobTitles: [],
                address: {
                    job_id: null,
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                googlePlaceAltered: false,
                countries: [],
                mapMarkers: [],
                validJobForm: false,
                startDateMenu: false,
                endDateMenu: false,
                bannerFormData: null,
                banner: null,
                showDeleteConfirmationOverlayBoolean: false,
                documentToDelete: null,
                showCancelEditConfirmationOverlayBoolean: false,
                paymentMilestones: [],
                paymentMilestonesCurrency: null,
                paymentOption: null,
                nextPath: null,
                validDescription: true,
                showCancelOverlayBoolean: false,
                paymentMilestonesInMemory: [],
                showSaveMilestonesConfirmationOverlayBoolean: false,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                showPaymentOverlayBoolean: false,
                isPrint: false
            }
        },

        beforeMount () {
            this.lookupJobController = new LookupJobController()
            this.lookupIndustryController = new LookupIndustryController()
            this.lookupJobTitleController = new LookupJobTitleController()
            this.lookupLocationController = new LookupLocationController()
            this.workJobDetailController = new WorkJobDetailController()
            this.workJobDocumentController = new WorkJobDocumentController()
            this.workJobLocationController = new WorkJobLocationController()
            this.workJobMilestoneController = new WorkJobMilestoneController()
            this.workJobStatusController = new WorkJobStatusController()
            this.generalInvoiceController = new GeneralInvoiceController()
        },

        async mounted () {
            this.getJobTypes()
            this.getJobModes()
            this.getIndustries()
            this.getJobTitles()
            this.getCountries()
            await this.getJobDetail()
            await this.getJobDocuments()
            await this.getJobLocation()
            await this.getJobPaymentMilestones()
        },

        methods: {
            printJob () {
                this.isPrint = true
                this.expandDates = true
                this.expandPaymentMilestones = true
                this.expandDetailedDescription = true
                this.expandDocuments = true

                this.$nextTick(() => {
                    const self = this

                    this.$htmlToPaper('print', null, () => {
                        self.isPrint = false
                        self.expandDates = false
                        self.expandPaymentMilestones = false
                        self.expandDetailedDescription = false
                        self.expandDocuments = false
                    })
                })
            },

            async getJobTypes () {
                try {
                    this.jobTypes = await this.lookupJobController.getJobTypes()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobModes () {
                try {
                    this.jobModes = await this.lookupJobController.getJobModes()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getIndustries () {
                try {
                    this.industries = await this.lookupIndustryController.get(false, false, true, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobTitles () {
                try {
                    this.jobTitles = await this.lookupJobTitleController.get(false, false, true, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCountries () {
                try {
                    this.countries = await this.lookupLocationController.getCountries()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobDetail () {
                try {
                    this.selectedJob = await this.workJobDetailController.retrieve(this.$route.params.id)
                    this.banner = this.selectedJob.image_url

                    if (this.selectedJob.status.id === 1) this.editMode = true
                    else this.editMode = false
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobDocuments () {
                try {
                    this.jobDocuments = await this.workJobDocumentController.retrieve(this.$route.params.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobLocation () {
                try {
                    this.address = await this.workJobLocationController.retrieve(this.selectedJob.job_id)

                    if (this.address.lat && this.address.lng) {
                        this.$nextTick(() => {
                            this.mapMarkers = [
                                {
                                    lat: Number(this.address.lat),
                                    lng: Number(this.address.lng)
                                }
                            ]
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobPaymentMilestones () {
                try {
                    this.paymentMilestones = await this.workJobMilestoneController.retrieve(this.$route.params.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async back (nextPath) {
                if (this.editMode) {
                    this.showCancelEditConfirmationOverlayBoolean = true
                    this.nextPath = nextPath
                } else this.$router.push(nextPath)
            },

            googlePlaceSelected (place) {
                const name = this.address.name
                const user_id = this.address.user_id

                this.googlePlaceAltered = false
                
                Object.assign(this.address, place)
                this.address.name = name
                this.address.user_id = user_id

                const countryIndex = this.countries.findIndex(country => country.iso_2 === this.address.country.country_code)
                if (countryIndex > -1) this.address.country.id = this.countries[countryIndex].id

                this.mapMarkers = [
                    {
                        lat: place.lat,
                        lng: place.lng
                    }
                ]
            },

            addressChanged (address, googlePlaceAltered) {
                this.googlePlaceAltered = googlePlaceAltered
                this.address.address_line_1 = address
                this.autocompleteLocationValidate()
            },

            autocompleteLocationValidate () {
                if (this.address.address_line_1 === '' || (this.address.address_line_1.replace(/\s/g, '') === '')) {
                    this.address = {
                        job_id: this.selectedJob.job_id,
                        user_id: this.address.user_id,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }
                }
            },

            async upsertJob (isPost) {
                try {
                    if (this.selectedJob.description === '<p></p>') this.selectedJob.description = null

                    if (this.selectedJob.job_type_name) delete this.selectedJob.job_type_name
                    if (this.selectedJob.job_name) delete this.selectedJob.job_name
                    if (this.selectedJob.job_banner) delete this.selectedJob.job_banner

                    const response = await this.workJobDetailController.upsert(this.selectedJob)

                    if (this.bannerFormData) {
                        try {
                            const uploadResponse = await this.workJobDocumentController.upsertBanner(response.job_id, this.bannerFormData)

                            this.banner = uploadResponse.image_url
                            this.bannerFormData = null
                        } catch (error) {
                            this.$store.commit('setSnackbar', {
                                text: error,
                                show: true
                            })
                        }
                    }

                    if (this.selectedJob.mode.id === 2 && this.address.google_places_id && !this.googlePlaceAltered) {
                        try {
                            this.address.job_id = response.job_id
                            await this.workJobLocationController.upsert(this.address)
                        } catch (error) {
                            this.$store.commit('setSnackbar', {
                                text: error,
                                show: true
                            })
                        }
                    }

                    if (!isPost) {
                        this.$store.commit('setSnackbar', {
                            text: 'Saved successfully',
                            show: true,
                            color: '#069B34'
                        })

                        this.editMode = false
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async recruit (afterPayment) {
                try {
                    if (!afterPayment) await this.upsertJob(true)
                    await this.workJobStatusController.recruit(this.selectedJob)

                    this.selectedJob.status.id = 2

                    this.$store.commit('setSnackbar', {
                        text: 'Created successfully',
                        show: true,
                        color: '#069B34'
                    })

                    // this.editMode = false
                    this.$router.push('/job/pending')
                } catch (error) {
                    if (error.errorCode) {
                        this.invoiceData = error.invoiceData
                        this.showPaymentOverlay(true)
                    } else {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            showPaymentOverlay (value, confirmRecruit, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmRecruit) this.payInvoice(this.invoiceData, selectedCard)
            },

            async payInvoice (invoice, card) {
                try {
                    await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)
                    await this.recruit(true)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            selectBanner () {
                this.$refs.banner.click()
            },

            bannerSelected (e) {
                const files = e.target.files

                if (files && files.length > 0) {
                    var reader = new FileReader()
                    var f = files[0]

                    var self = this

                    reader.onloadend = async function () {
                        self.banner = reader.result
                        self.bannerFormData = new FormData()
                        self.bannerFormData.append('image', files[0])
                    }

                    reader.readAsDataURL(f)
                }
            },

            async createDocument(document) {
                try {
                    this.jobDocuments = await this.workJobDocumentController.create(this.$route.params.id, document)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async confirmDeleteDocument(document) {
                this.documentToDelete = document
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteDocument(document) {
                try {
                    this.jobDocuments = await this.workJobDocumentController.delete(this.$route.params.id, [document])

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.documentToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async downloadDocument(documentToDownload) {
                try {
                    const downloadResponse = await this.workJobDocumentController.download(this.$route.params.id, encodeURIComponent(documentToDownload.fullpath))

                    var data = new Blob([downloadResponse])
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(data, documentToDownload.filename)
                    } else {
                        var blob = data
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = documentToDownload.filename

                        document.body.appendChild(link)

                        link.click()
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Downloaded successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteDocument(this.documentToDelete)
            },

            async showCancelEditConfirmationOverlay (saveChanges, cancel) {
                if (cancel) {
                    this.showCancelEditConfirmationOverlayBoolean = false
                }
                else if ((saveChanges && this.selectedJob.status.id === 1) || (saveChanges && !this.cannotSaveJob)) {
                    await this.upsertJob()
                    this.$router.push(this.nextPath)
                } else if (saveChanges && this.cannotSaveJob) {
                    this.showCancelEditConfirmationOverlayBoolean = false

                    this.$store.commit('setSnackbar', {
                        text: 'Complete all required fields',
                        show: true
                    })
                } else this.$router.push(this.nextPath)
            },

            descriptionChanged (text) {
                this.selectedJob.description = text
                if (this.selectedJob.description === '<p></p>') {
                    this.selectedJob.description = null
                    this.validDescription = false
                } else this.validDescription = true
            },

            confirmSavePaymentMilestones () {
                this.showSaveMilestonesConfirmationOverlay(true)
            },

            showSaveMilestonesConfirmationOverlay (value, confirmSave) {
                this.showSaveMilestonesConfirmationOverlayBoolean = value

                if (confirmSave) this.savePaymentMilestones(this.paymentMilestonesInMemory)
            },

            async savePaymentMilestones (milestones) {
                try {
                    if (milestones && milestones.length > 0) {
                        let milestoneID = null

                        if (this.paymentMilestones.length > 0) {
                            const milestoneIndex = this.paymentMilestones.findIndex(itemToFind => itemToFind.milestone_id)
                            if (milestoneIndex > -1) milestoneID = this.paymentMilestones[milestoneIndex].milestone_id
                        }

                        for (let index = 0; index < milestones.length; index++) {
                            const milestone = milestones[index]
                            milestone.job_id = this.selectedJob.job_id
                            milestone.milestone_id = milestoneID
                            delete milestone.milestoneDateMenu
                            delete milestone.validMilestoneForm

                            const response = await this.workJobMilestoneController.upsert(milestone)
                            milestoneID = response[0].milestone_id
                        }

                        this.paymentMilestonesInMemory = []
                        this.getJobPaymentMilestones()
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async removePaymentMilestone (milestone) {
                try {
                    milestone.job_id = this.selectedJob.job_id
                    delete milestone.milestoneDateMenu
                    delete milestone.validMilestoneForm

                    this.paymentMilestones = await this.workJobMilestoneController.delete(milestone)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmCancelJob () {
                this.toggleCancelOverlayComponent(true)
            },

            toggleCancelOverlayComponent (value, confirmCancellation) {
                this.showCancelOverlayBoolean = value

                if (confirmCancellation) this.cancelJob()
            },

            async cancelJob () {
                try {
                    await this.workJobStatusController.cancel(this.selectedJob)

                    this.$store.commit('setSnackbar', {
                        text: 'Job cancelled',
                        show: true,
                        color: '#069B34'
                    })

                    this.$router.push('/job/history')
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            getPaymentMilestonesInMemory (milestones) {
                this.paymentMilestonesInMemory = milestones
            }
        }
    }
</script>
<style>
    .v-application p {
        margin-bottom: 12px;
    }
</style>
<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
