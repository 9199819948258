<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-12 13:28:33
 * @ Description: Component for formatted text input.
 -->

<template>
    <div>
        <tiptap-vuetify
            class="pt-2 rounded-lg"
            :class="limitHeight ? 'limited' : 'unlimited'"
            v-model="content"
            :extensions="extensions"
            :placeholder="!placeholder ? 'Specific details about the service that you offer...' : placeholder"
        />
    </div>
</template>

<script>
    import { TiptapVuetify, Bold, Italic, Underline, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'

    export default {
        name: 'GeneralTipTapTextEditorComponent',

        props: {
            text: {
                type: String
            },

            placeholder: {
                type: String,
                default: null
            },

            limitHeight: {
                type: Boolean,
                default: true
            }
        },

        watch: {
            text: {
                handler (value) {
                    if (value) this.content = value
                },
                immediate: true
            },

            content: {
                handler (value) {
                    this.$emit('textChanged', value)
                },
                immediate: true
            }
        },

        components: {
            TiptapVuetify
        },

        data () {
            return {
                content: null,
                extensions: [
                    Bold,
                    Underline,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList
                ]
            }
        }
    }
</script>
<style scoped>
    >>> .limited .tiptap-vuetify-editor__content {
        font-size: 16px !important;
        height: 100px;
        max-height: 100px;
    }

    >>> .unlimited .tiptap-vuetify-editor__content {
        font-size: 16px !important;
        min-height: 100px;
    }

    >>> .tiptap-vuetify-editor__content p.tiptap-vuetify-editor__paragraph--is-empty:first-child:before {
        font-style: unset;
        font-weight: normal;
        color: #8F9BB3;
    }

    >>> .v-btn--active {
        color: black !important;
    }

    >>> .ProseMirror p {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    >>> .ProseMirror {
        margin: 8px !important;
        font-size: 13px !important;
        font-weight: normal !important;
    }
</style>
