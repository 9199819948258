<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-18 10:31:31
 * @ Description: Overlay to confirm cancellation of editing of an item.
 -->

<template>
    <v-dialog
        v-model="showCancelEditConfirmationOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        style="z-index: 10;"
        max-width="350px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            max-height="450px"
        >
            <v-card-text
                class="pa-3"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                    align="center"
                >
                    <v-col
                        cols="12"
                        style="background-color: rgba(255, 168, 88, 0.25);"
                    >
                        <v-row
                            class="py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Unsaved changes will be lost. Do you wish to save first?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="showCancelEditConfirmationOverlay(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#FFA858"
                                    @click="showCancelEditConfirmationOverlay(true)"
                                >
                                    Yes
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    :class="$store.state.general.customBreakpoints.xxs ? 'mt-2' : ''"
                                    color="#2E567A"
                                    style="opacity: 0.5;"
                                    @click="showCancelEditConfirmationOverlay(null, true)"
                                >
                                    Cancel
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'GeneralConfirmCancelEditOverlayComponent',

        props: {
            showCancelEditConfirmationOverlayBoolean: {
                type: Boolean,
                required: true
            }
        },

        methods: {
            showCancelEditConfirmationOverlay (saveChanges, cancel) {
                this.$emit('showCancelEditConfirmationOverlay', saveChanges, cancel)
            }
        }
    }
</script>
